import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import cx from 'classnames';
import { useIpContext } from '@lib/ipContext';

import Chevron from '@svg/chevron-down-white.svg';

const GroupItem = ({
  groupName,
  groupMembersArray,
  active,
  setActive,
  index,
  groupDetailsComplete,
  handleGroupNav,
  handleMemberNav,
}) => {
  const isActive = active === index;
  const handleClick = () => (!isActive ? setActive(index) : setActive(null));

  return (
    <div className='w-full h-fit lg:h-full  '>
      <div
        className='flex justify-between items-center cursor-pointer  pb-2'
        onClick={handleClick}>
        <div className='flex justify-between w-full'>
          <div className='flex space-x-5'>
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: isActive ? 180 : 0 }}
              transition={{
                duration: 0.5,
              }}
              className='flex items-center'>
              <Chevron className='svg-sand w-5 h-auto self-center' />
            </motion.div>
            <h1 onClick={() => handleGroupNav(index)} className='blockH6'>
              {groupName}
            </h1>
          </div>
          <motion.div
            onClick={() => handleGroupNav(index)}
            className={cx(
              ' h-7 w-7  rounded-full border-sand border self-center transition-standard ',
              {
                'bg-sand': groupDetailsComplete,
              },
            )}
          />
        </div>
      </div>

      <div className=' w-full h-[2px] '></div>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className='overflow-hidden'
            transition={{
              duration: 0.5,
            }}>
            <div className=' w-full h-4'></div>
            <div className='space-y-2 pl-10'>
              {groupMembersArray?.map((member, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => handleMemberNav(i, index)}
                  className={cx('flex justify-between w-full cursor-pointer', {
                    hidden: !member?.memberName.length > 0,
                  })}>
                  <p className=' ' key={i}>
                    {member?.memberName}
                  </p>
                  <div
                    className={cx(
                      ' h-5 w-5 rounded-full border-sand border self-center transition-standard ',
                      {
                        'bg-sand': member?.memberComplete,
                      },
                    )}
                  />
                </motion.div>
              ))}
            </div>
            <div className=' w-full h-3'></div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const GroupSidebar = ({
  currentGroup,
  groupInformation,
  setGroupInformation,
  groups,
}) => {
  const [active, setActive] = useState(currentGroup);

  const { ipState, dispatch } = useIpContext();

  const handleGroupNav = (index) => {
    dispatch({ type: 'updateGroupNav', payload: index });
  };

  const handleMemberNav = (index, groupIndex) => {
    setGroupInformation({
      ...groupInformation,
      currentMember: index,
      groupDetailsComplete: true,
    });

    dispatch({ type: 'updateGroup', payload: groupInformation });
    dispatch({ type: 'updateMemberGroupNav', payload: groupIndex });
  };

  return (
    <div className='col-start-1 col-end-6  entry-title-container  xl:sticky xl:top-5 xl:self-start'>
      <div>
        <h4 className='blockH4 mb-2'>{`Group ${
          currentGroup + 1
        } Information`}</h4>
        <p>
          Groups may perform multiple works by more than one composer. You may
          enter up to 3 pieces in the section below. The total performance time
          for each group must not exceed 15 minutes. Tuning time and pauses
          between movements will not count as performing time.
        </p>
      </div>
      <div className='flex flex-col space-y-[20px] mt-[70px]'>
        {groups?.map((i, index) => (
          <GroupItem
            {...i}
            key={index}
            index={index}
            active={active}
            setActive={setActive}
            handleGroupNav={handleGroupNav}
            handleMemberNav={handleMemberNav}
          />
        ))}
      </div>
    </div>
  );
};

export default GroupSidebar;
