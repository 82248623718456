import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

import InstrumentalStepsContainer from '../../components/entryForm/instrumental/instrumentalStepsContainer';
import { StateProvider } from '../../lib/ipContext';

import InstrumentalHeader from '../../components/nav/instrumentalHeader';
import IpFormFooter from '../../components/entryForm/ipFormFooter';

import '@css/tailwind.css';
import '@css/main.css';

const InstrumentalPerformancePage = () => {
  // start at top of the page
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <StateProvider>
      <AnimatePresence exitBeforeEnter>
        <div className='entry-form-container w-full h-full min-h-screen pb-20'>
          <InstrumentalHeader />
          <InstrumentalStepsContainer />
          <IpFormFooter />
        </div>
      </AnimatePresence>
    </StateProvider>
  );
};

export default InstrumentalPerformancePage;
