import React from 'react';
import { useIpContext } from '@lib/ipContext';
import cx from 'classnames';
import { Link } from 'gatsby';

const IpFormFooter = () => {
  const { ipState, dispatch } = useIpContext();

  const handleBack = () => {
    dispatch({ type: 'updateCurrentStep', payload: ipState.currentStep - 1 });
    //scrtoll to top
    window.scroll(0, 0);
  };

  const handleNext = () => {
    const inputs = document.querySelectorAll('input, select, checkbox');
    inputs.forEach((input) => {
      input.focus();
      input.blur();
    });

    window.scroll(0, 0);
  };

  const handleSubmit = async () => {
    const response = await fetch('/.netlify/functions/send-ip-entry', {
      method: 'POST',
      body: JSON.stringify(ipState),
    });

    if (response.status === 200) {
      localStorage.removeItem('ipState');
      dispatch({ type: 'resetState' });
    }

    if (response.status === 400) {
      console.log('error');
    }
  };

  return (
    <div
      className={cx('flex justify-between mt-20', {
        hidden: false,
      })}>
      {ipState?.currentStep !== 1 ? (
        <button onClick={handleBack} className='btn-entry-nav'>
          Back
        </button>
      ) : (
        <Link to='/contest' className='btn-entry-nav'>
          Back
        </Link>
      )}
      {ipState.currentStep !== 5 ? (
        <button
          type='submit'
          form={
            ipState.currentStep === 1
              ? 'ip-contact-form'
              : ipState.currentStep === 2
              ? 'ip-billing-details'
              : ipState.currentStep === 3
              ? 'ip-region-group'
              : ipState.currentStep === 4
              ? 'ip-group-info'
              : null
          }
          // form="primary-contact-form"
          onClick={handleNext}
          className='btn-entry-nav'>
          {' '}
          Next
        </button>
      ) : (
        <Link
          to='/contest/instrumental-confirmation'
          onClick={handleSubmit}
          className='btn-entry-nav'>
          Submit Entry
        </Link>
      )}
    </div>
  );
};

export default IpFormFooter;
