import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIpContext } from '@lib/ipContext';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

const RegionGroup = () => {
  const { ipState, dispatch } = useIpContext();
  const [regionGroup, setRegionGroup] = useState(ipState.regionGroup);

  const handleInputChange = (e) => {
    const previousState = regionGroup;
    const { name, value } = e.target;
    previousState[name] = value;
    setRegionGroup(previousState);
    dispatch({ type: 'updateRegionGroup', payload: previousState });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    console.log('submitted');
    dispatch({ type: 'updateCurrentStep', payload: 4 });
  };

  const instrumentalRegions = [
    'Northland (Whangārei)',
    'Auckland',
    'Waikato',
    'Bay of Plenty (Tauranga)',
    'Taranaki (New Plymouth)',
    'Tairāwhiti (Gisborne)',
    'Hawke’s Bay (Napier)',
    'Whanganui',
    'Manawatū (Palmerston North)',
    'Wellington',
    'Nelson-Marlborough',
    'Canterbury (Christchurch)',
    'Otago (Dunedin)',
    'Southland (Invercargill)',
  ];

  const instrumentalGroups = ['1', '2', '3', '4', '5'];

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='entry-step-container'>
        <form onSubmit={handleSubmit(onSubmit)} id='ip-region-group'>
          <div className='xl:grid grid-cols-12 gap-y-[120px] '>
            <div className='col-start-1 col-end-6 mb-7 '>
              <h4 className='blockH4 mb-2'>
                Which District/Region will you be entering?
              </h4>
            </div>
            <div className='col-start-8 col-span-full self-center mb-8 sm:mb-14 xl:mb-0'>
              <div className='relative'>
                {ipState?.regionGroup?.instrumentalRegion && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    Select Region
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.instrumentalRegion?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.instrumentalRegion?.type === 'required',
                  })}
                  value={ipState?.regionGroup?.instrumentalRegion}
                  {...register('instrumentalRegion', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    Select Region
                  </option>
                  {instrumentalRegions.map((region) => (
                    <option value={region}>{region}</option>
                  ))}
                </select>
                {errors.instrumentalRegion?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
            </div>

            <div className='col-start-1 col-end-6 mb-7'>
              <h4 className='blockH4 mb-2'>
                How many groups are you going to enter?
              </h4>
            </div>
            <div className='col-start-8 col-span-full self-center'>
              <div className='relative'>
                {ipState?.regionGroup?.instrumentalGroups && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    Groups
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.instrumentalGroups?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.instrumentalGroups?.type === 'required',
                  })}
                  value={ipState?.regionGroup?.instrumentalGroups}
                  {...register('instrumentalGroups', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    Groups
                  </option>
                  {instrumentalGroups.map((region) => (
                    <option value={region}>{region}</option>
                  ))}
                </select>
                {errors.instrumentalGroups?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
            </div>
          </div>
        </form>
      </motion.div>
    </AnimatePresence>
  );
};

export default RegionGroup;
