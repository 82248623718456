import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIpContext } from '@lib/ipContext';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

const BillingDetails = () => {
  const { ipState, dispatch } = useIpContext();
  const [billingDetails, setBillingDetails] = useState(ipState.billingDetails);

  const handleInputChange = (e) => {
    const previousState = billingDetails;
    const { name, value } = e.target;
    previousState[name] = value;
    setBillingDetails(previousState);
    dispatch({ type: 'updateBillingDetails', payload: previousState });
  };

  const handleRulesAccepted = (e) => {
    // setRulesAccepted(e.target.checked);
    dispatch({ type: 'updateRulesAndConditions', payload: e.target.checked });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    console.log('submitted');
    dispatch({ type: 'updateCurrentStep', payload: 3 });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='entry-step-container'>
        <div className='xl:grid grid-cols-12'>
          <div className='col-start-1 col-end-6  entry-title-container  xl:sticky xl:top-5 xl:self-start'>
            <h4 className='blockH4 mb-2'>Billing Details</h4>
            <p>
              This billpayer wil be sent an invoice for entry fees in the week
              of 8-12 April 2024.
            </p>
          </div>
          <div className='col-start-8 col-span-full mb-10 xl:mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} id='ip-billing-details'>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.instrumentalBillingName?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.instrumentalBillingName?.type === 'required',
                  })}
                  value={ipState?.billingDetails?.instrumentalBillingName}
                  {...register('instrumentalBillingName', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='instrumentalBillingName'
                  className='entryLabel pointer-events-none '>
                  Full Name*
                </label>
                {errors.instrumentalBillingName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.instrumentalBillingSchool?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.instrumentalBillingSchool?.type === 'required',
                  })}
                  value={ipState?.billingDetails?.instrumentalBillingSchool}
                  {...register('instrumentalBillingSchool', {
                    onChange: handleInputChange,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='instrumentalBillingSchool'
                  className='entryLabel pointer-events-none '>
                  School Name (if applicable)
                </label>
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.instrumentalBillingEmail?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.instrumentalBillingEmail?.type === 'required',
                  })}
                  value={ipState?.billingDetails?.instrumentalBillingEmail}
                  {...register('instrumentalBillingEmail', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='instrumentalBillingEmail'
                  className='entryLabel pointer-events-none '>
                  Billing Email Address*
                </label>
                {errors.instrumentalBillingEmail?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.instrumentalBillingAddress?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.instrumentalBillingAddress?.type === 'required',
                  })}
                  value={ipState?.billingDetails?.instrumentalBillingAddress}
                  {...register('instrumentalBillingAddress', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='instrumentalBillingAddress'
                  className='entryLabel pointer-events-none '>
                  Billing Address*
                </label>
                {errors.instrumentalBillingAddress?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.instrumentalBillingSuburb?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.instrumentalBillingSuburb?.type === 'required',
                  })}
                  value={ipState?.billingDetails?.instrumentalBillingSuburb}
                  {...register('instrumentalBillingSuburb', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='instrumentalBillingSuburb'
                  className='entryLabel pointer-events-none '>
                  Suburb*
                </label>
                {errors.instrumentalBillingSuburb?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.instrumentalBillingCity?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.instrumentalBillingCity?.type === 'required',
                  })}
                  value={ipState?.billingDetails?.instrumentalBillingCity}
                  {...register('instrumentalBillingCity', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='instrumentalBillingCity'
                  className='entryLabel pointer-events-none '>
                  City*
                </label>
                {errors.instrumentalBillingCity?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.instrumentalBillingTown?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.instrumentalBillingTown?.type === 'required',
                  })}
                  value={ipState?.billingDetails?.instrumentalBillingTown}
                  {...register('instrumentalBillingTown', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='instrumentalBillingTown'
                  className='entryLabel pointer-events-none '>
                  Town*
                </label>
                {errors.instrumentalBillingTown?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='number'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.instrumentalBillingPostcode?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.instrumentalBillingPostcode?.type === 'required',
                  })}
                  value={ipState?.billingDetails?.instrumentalBillingPostcode}
                  {...register('instrumentalBillingPostcode', {
                    onChange: handleInputChange,
                    required: true,
                    valueAsNumber: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='instrumentalBillingPostcode'
                  className='entryLabel pointer-events-none '>
                  Postcode*
                </label>
                {errors.instrumentalBillingPostcode?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div className='relative'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    // name="rulesAndConditions"
                    // onChange={handleRulesAccepted}
                    value={ipState?.rulesAndConditions}
                    checked={ipState?.rulesAndConditions}
                    {...register('rulesAndConditions', {
                      onChange: handleRulesAccepted,
                      required: true,
                      setValueAs: (value) => value,
                      checked: ipState?.rulesAndConditions,
                    })}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <span className={'cursor-pointer blockH8'}>
                    I understand and agree to abide by the NZCT Chamber Music
                    Contest{' '}
                    <a
                      target='_blank'
                      className='inline-block underline underline-offset-2 z-20'
                      href='https://cdn.sanity.io/files/dogza56w/production/c56d8fed8988c0319da25a428e1de18d759c8d62.pdf'>
                      Rules and Conditions of Entry (2024)
                    </a>
                    .
                  </span>
                </label>
                {errors.rulesAndConditions?.type === 'required' && (
                  <p className='entryError'>
                    Rules and Conditions is required to proceed
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default BillingDetails;
