import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIpContext } from '@lib/ipContext';
import GroupSidebar from '../groupSidebar';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

const GroupInfo = ({ groupInformation, setGroupInformation }) => {
  const { ipState, dispatch } = useIpContext();

  const handleInputChange = (e) => {
    let previousState = groupInformation;
    const { name, value } = e.target;
    previousState[name] = value;
    dispatch({ type: 'updateGroup', payload: previousState });
  };

  const handleCheckboxChange = (e) => {
    let previousState = groupInformation;
    const { name, checked } = e.target;
    previousState[name] = checked;
    dispatch({ type: 'updateGroup', payload: previousState });
  };

  const handleCoachDateChange = (e) => {
    const { value } = e.target;
    const isSelected = groupInformation.coachingDate.includes(value);
    const isLastItem = value === coachDates[coachDates.length - 1];

    let newCoachingDates;

    if (isSelected) {
      // If the item is already selected, remove it
      newCoachingDates = groupInformation.coachingDate.filter(
        (date) => date !== value,
      );
    } else if (isLastItem) {
      // If the last item is selected, clear other selections
      newCoachingDates = [value];
    } else {
      // If any other item is selected, add it and remove the last item if it was selected
      newCoachingDates = groupInformation.coachingDate.filter(
        (date) => date !== coachDates[coachDates.length - 1],
      );
      newCoachingDates.push(value);
    }

    setGroupInformation((prev) => ({
      ...prev,
      coachingDate: newCoachingDates,
    }));
  };

  const coachDates = [
    'The group is available for coaching 6 May',
    'The group is available for coaching 7 May',
    'The group is available for coaching 20 May',
    'The group is available for coaching 21 May',
    'The group is not available for any of the above dates',
  ];

  const memberObject = {
    memberName: '',
    memberPreferredName: '',
    memberEmail: '',
    instrumentVoice: '',
    townCity: '',
    memberSchool: '',
    memberSchoolYear: '',
    memberEthnicities: '',
    composerOtherEthnicities: '',
    memberComplete: false,
  };

  const musicPieceObject = {
    musicTitle: '',
    musicComposer: '',
    musicDuration: '',
  };

  const handleMembersChange = (e) => {
    let previousState = groupInformation;
    const { name, value } = e.target;
    previousState[name] = value;
    //add the amount of groupMembers number of objects with the groupMember template to the groupMembersArray inside the groups object
    if (name === 'groupMembers') {
      previousState.groupMembersArray = [];
      for (let i = 0; i < value; i++) {
        previousState.groupMembersArray.push(memberObject);
      }
    }
    dispatch({ type: 'updateGroup', payload: previousState });
  };

  const handleMusicPiecesChange = (e) => {
    let previousState = groupInformation;
    const { name, value } = e.target;
    previousState[name] = value;

    if (name === 'musicPieces') {
      previousState.musicPiecesArray = [];
      for (let i = 0; i < value; i++) {
        previousState.musicPiecesArray.push(musicPieceObject);
      }
    }
    dispatch({ type: 'updateGroup', payload: previousState });
  };

  const handleMusicPieceDetailsChange = (e, index) => {
    const { name, value } = e.target;

    const updatedName = name.slice(0, -1);

    setGroupInformation((prevState) => ({
      ...prevState,
      musicPiecesArray: prevState.musicPiecesArray.map((musicPiece, i) => {
        if (i === index) {
          return { ...musicPiece, [updatedName]: value };
        } else {
          return musicPiece;
        }
      }),
    }));

    dispatch({ type: 'updateGroup', payload: groupInformation });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    setGroupInformation((prevState) => ({
      ...prevState,
      groupDetailsComplete: true,
    }));
    dispatch({ type: 'updateGroup', payload: groupInformation });
  };

  const players = ['3', '4', '5', '6', '7', '8'];

  // create an array of durations up to 15 minutes, with "minute" or "minutes" appended to the end
  const durations = [];
  for (let i = 1; i <= 15; i++) {
    if (i === 1) {
      durations.push(`${i} minute`);
    } else {
      durations.push(`${i} minutes`);
    }
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='entry-step-container'>
        <div className='xl:grid grid-cols-12'>
          <GroupSidebar
            currentGroup={ipState.currentGroup}
            groupInformation={groupInformation}
            setGroupInformation={setGroupInformation}
            groups={ipState?.groups}
          />

          <div className='col-start-8 col-span-full mb-10 xl:mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} id='ip-group-info'>
              <h5 className='blockH6 mb-9'>
                Group {ipState.currentGroup + 1} Information
              </h5>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.groupName?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.groupName?.type === 'required',
                  })}
                  value={groupInformation?.groupName}
                  {...register('groupName', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='groupName'
                  className='entryLabel pointer-events-none '>
                  Group Name*
                </label>
                {errors.groupName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <h5 className='blockH6 mb-9 mt-20'>Tutor/Teacher Information</h5>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.groupTutorName?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.groupTutorName?.type === 'required',
                  })}
                  value={groupInformation?.groupTutorName}
                  {...register('groupTutorName', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='groupTutorName'
                  className='entryLabel pointer-events-none '>
                  Full Name*
                </label>
                {errors.groupTutorName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.groupTutorPhone?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.groupTutorPhone?.type === 'required',
                  })}
                  value={groupInformation?.groupTutorPhone}
                  {...register('groupTutorPhone', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='groupTutorPhone'
                  className='entryLabel pointer-events-none '>
                  Phone Number*
                </label>
                {errors.groupTutorPhone?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.groupTutorEmail?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.groupTutorEmail?.type === 'required',
                  })}
                  value={groupInformation?.groupTutorEmail}
                  {...register('groupTutorEmail', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='groupTutorEmail'
                  className='entryLabel pointer-events-none '>
                  Email Address*
                </label>
                {errors.groupTutorEmail?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div className='relative'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    // name="rulesAndConditions"
                    // onChange={handleRulesAccepted}
                    value={groupInformation?.groupConditions}
                    checked={groupInformation?.groupConditions}
                    {...register('groupConditions', {
                      onChange: handleCheckboxChange,
                      required: true,
                      setValueAs: (value) => value,
                      checked: groupInformation?.groupConditions,
                    })}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <span className={'cursor-pointer blockH8'}>
                    I agree with and understand that all teachers and
                    accompanying support attending the contest will be asked to
                    comply with the venue{' '}
                    <a
                      target='_blank'
                      className='inline-block underline underline-offset-2 z-20'
                      href='https://cdn.sanity.io/files/dogza56w/production/c56d8fed8988c0319da25a428e1de18d759c8d62.pdf'>
                      {' '}
                      conditions of entry
                    </a>
                    .
                  </span>
                </label>
                {errors.groupConditions?.type === 'required' && (
                  <p className='entryError'>
                    Rules and Conditions is required to proceed
                  </p>
                )}
              </div>

              <h5 className='blockH6 mb-9 mt-20'>Number of Players</h5>

              <div className='relative'>
                {groupInformation?.groupMembers !== 0 && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    Players
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.groupMembers?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.groupMembers?.type === 'required',
                  })}
                  value={groupInformation?.groupMembers}
                  {...register('groupMembers', {
                    onChange: handleMembersChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    Players
                  </option>
                  {players.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                {errors.groupMembers?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <h5 className='blockH6 mb-9 mt-20'>Performance Information</h5>

              <div className='relative pt-5'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    // name="rulesAndConditions"
                    // onChange={handleRulesAccepted}
                    value={groupInformation?.originalComposition}
                    checked={groupInformation?.originalComposition}
                    {...register('originalComposition', {
                      onChange: handleCheckboxChange,
                      setValueAs: (value) => value,
                      checked: groupInformation?.originalComposition,
                    })}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <span className={'cursor-pointer blockH8'}>
                    This is a performance of an Original Composition Entry.
                    Please note that in order for the Instrumental Performance
                    Fee to be waived, the corresponding submission must be
                    entered by 5 April 2024.
                  </span>
                </label>
              </div>

              <AnimatePresence>
                {groupInformation?.originalComposition && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 200 }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5 }}
                    className='overflow-hidden'>
                    <div className='h-5' />
                    <div class='entryInputContainer  group ml-[42px] xl:ml-[51px]  '>
                      <input
                        name='originalCompositionTitle'
                        type='text'
                        placeholder=' '
                        className='entryInput peer focus:border-sand border-sand'
                        onChange={handleInputChange}
                        value={groupInformation?.originalCompositionTitle}
                      />
                      <label
                        for='originalCompositionTitle'
                        className='entryLabel pointer-events-none  '>
                        Composition Entry Title
                      </label>
                    </div>
                    <div class=' entryInputContainer  group ml-[42px] xl:ml-[51px]  '>
                      <input
                        name='originalCompositionComposer'
                        type='text'
                        placeholder=' '
                        className='entryInput peer focus:border-sand border-sand'
                        onChange={handleInputChange}
                        value={groupInformation?.originalCompositionComposer}
                      />
                      <label
                        for='originalCompositionComposer'
                        className='entryLabel pointer-events-none  '>
                        Name of Composer
                      </label>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>

              <div className='relative pt-5'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    // name="rulesAndConditions"
                    // onChange={handleRulesAccepted}
                    value={groupInformation?.requiresPiano}
                    checked={groupInformation?.requiresPiano}
                    {...register('requiresPiano', {
                      onChange: handleCheckboxChange,
                      setValueAs: (value) => value,
                      checked: groupInformation?.requiresPiano,
                    })}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <span className={'cursor-pointer blockH8'}>
                    The piece we are performing required prepared piano.
                  </span>
                </label>
              </div>

              <div className='relative pt-5'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    // name="rulesAndConditions"
                    // onChange={handleRulesAccepted}
                    value={groupInformation?.requiresMorePianos}
                    checked={groupInformation?.requiresMorePianos}
                    {...register('requiresMorePianos', {
                      onChange: handleCheckboxChange,
                      setValueAs: (value) => value,
                      checked: groupInformation?.requiresMorePianos,
                    })}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <span className={'cursor-pointer blockH8'}>
                    We will be needing more than one piano. Please note that
                    only one piano will be provided at all Contest venues. Any
                    additional pianos must be provided by the group and at the
                    group’s own expense. Please email{' '}
                    <a
                      className='inline-block underline underline-offset-2 z-20'
                      href='mailto:bleau@chambermusic.co.nz'>
                      bleau@chambermusic.co.nz
                    </a>{' '}
                    to discuss.
                  </span>
                </label>
              </div>

              <div className='relative pt-5'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    // name="rulesAndConditions"
                    // onChange={handleRulesAccepted}
                    value={groupInformation?.nzWork}
                    checked={groupInformation?.nzWork}
                    {...register('nzWork', {
                      onChange: handleCheckboxChange,
                      setValueAs: (value) => value,
                      checked: groupInformation?.nzWork,
                    })}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <span className={'cursor-pointer blockH8'}>
                    We are performing a New Zealand work.
                  </span>
                </label>
              </div>

              <div className='relative pt-5'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    // name="rulesAndConditions"
                    // onChange={handleRulesAccepted}
                    value={groupInformation?.freeCoaching}
                    checked={groupInformation?.freeCoaching}
                    {...register('freeCoaching', {
                      onChange: handleCheckboxChange,
                      setValueAs: (value) => value,
                      checked: groupInformation?.freeCoaching,
                    })}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <div className={'cursor-pointer blockH8 relative'}>
                    <span>
                      We would like to be considered for a free coaching session
                      with a professional musician. These will take place in May
                      2024. Spaces Limited.
                    </span>

                    <AnimatePresence>
                      {groupInformation?.freeCoaching && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 200 }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.5 }}
                          className={cx('flex items-start my-3 flex-col')}>
                          {coachDates.map((item, i) => (
                            <label
                              key={item + i}
                              className='flex gap-[12px] items-start mb-3 xl:gap-[25px]'>
                              <input
                                type='checkbox'
                                value={item}
                                checked={groupInformation?.coachingDate?.includes(
                                  item,
                                )}
                                onChange={handleCoachDateChange}
                              />
                              <span className='checkmark h-5 w-5 shrink-0 rounded-full border-sand border self-center transition-standard' />
                              <span className='cursor-pointer blockH8'>
                                {item}
                              </span>
                            </label>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </label>
              </div>

              <h5 className='blockH6 mb-9 mt-20'>Music Information</h5>

              <div className='relative'>
                {groupInformation?.musicPieces.length !== 0 && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    Number of Pieces
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.musicPieces?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.musicPieces?.type === 'required',
                  })}
                  value={groupInformation?.musicPieces}
                  {...register('musicPieces', {
                    onChange: handleMusicPiecesChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    Number of Pieces
                  </option>
                  <option value='1'>1 Piece</option>
                  <option value='2'>2 Pieces</option>
                  <option value='3'>3 Pieces</option>
                </select>
                {errors.musicPieces?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <AnimatePresence>
                {groupInformation?.musicPiecesArray?.map(
                  (musicPiece, index) => {
                    return (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <h5 className='blockH6 mb-9 mt-20'>
                          Music {index + 1}
                        </h5>
                        <div class=' entryInputContainer group '>
                          <input
                            type='text'
                            placeholder=' '
                            className={cx(
                              'entryInput peer focus:border-sand border-sand ',
                              {},
                            )}
                            value={
                              groupInformation?.musicPiecesArray[index]
                                ?.musicTitle
                            }
                            {...register(`musicTitle${index}`, {
                              onChange: (e) =>
                                handleMusicPieceDetailsChange(e, index),
                              setValueAs: (value) => value,
                            })}
                          />
                          <label
                            for={`musicTitle${index}`}
                            className='entryLabel pointer-events-none '>
                            Title of Music {index + 1}
                          </label>
                        </div>

                        <div class=' entryInputContainer group'>
                          <input
                            type='text'
                            placeholder=' '
                            className={cx(
                              'entryInput peer focus:border-sand border-sand ',
                              {},
                            )}
                            value={
                              groupInformation?.musicPiecesArray[index]
                                ?.musicComposer
                            }
                            {...register(`musicComposer${index}`, {
                              onChange: (e) =>
                                handleMusicPieceDetailsChange(e, index),
                              setValueAs: (value) => value,
                            })}
                          />
                          <label
                            for={`musicComposer${index}`}
                            className='entryLabel pointer-events-none '>
                            Composer of Music {index + 1}
                          </label>
                        </div>

                        <div className='relative'>
                          {groupInformation?.musicPiecesArray[index]
                            ?.musicDuration !== 0 && (
                            <motion.span
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.5 }}
                              className='selectLabel '>
                              Total Duration of Music {index + 1}
                            </motion.span>
                          )}
                          <select
                            id=''
                            className={cx(
                              'entrySelect focus:border-sand border-sand',
                              {},
                            )}
                            value={
                              groupInformation?.musicPiecesArray[index]
                                ?.musicDuration
                            }
                            {...register(`musicDuration${index}`, {
                              onChange: (e) =>
                                handleMusicPieceDetailsChange(e, index),
                              setValueAs: (value) => value,
                            })}>
                            <option value='' disabled selected>
                              Total Duration of Music {index + 1} (nearest
                              minute)
                            </option>
                            {durations.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </motion.div>
                    );
                  },
                )}
              </AnimatePresence>
            </form>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default GroupInfo;
