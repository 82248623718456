import React, { useState, useEffect } from 'react';
import { useIpContext } from '@lib/ipContext';

import GroupInfo from './groupInfo';
import MemberInfo from './groupMember';

const GroupStepsContainer = () => {
  const { ipState, dispatch } = useIpContext();
  const [groupInformation, setGroupInformation] = useState(
    ipState.groups[ipState.currentGroup],
  );

  useEffect(() => {
    setGroupInformation(ipState.groups[ipState.currentGroup]);
  }, [ipState?.currentGroup, ipState?.stateToggle]);

  return (
    <div>
      {!groupInformation?.groupDetailsComplete ? (
        <GroupInfo
          groupInformation={groupInformation}
          setGroupInformation={setGroupInformation}
        />
      ) : (
        <MemberInfo
          groupInformation={groupInformation}
          setGroupInformation={setGroupInformation}
        />
      )}
    </div>
  );
};

export default GroupStepsContainer;
