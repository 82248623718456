import React, { useEffect } from 'react';
import { useIpContext } from '@lib/ipContext';

import PrimaryContact from './primaryContact';
import BillingDetails from './billingDetails';
import RegionGroup from './regionGroup';
import InstrumentalEntrySummary from './entrySummary';

import GroupStepsContainer from './groupStepsContainer';

const InstrumentalStepsContainer = () => {
  const { ipState } = useIpContext();

  return (
    <div className='entry-form-content'>
      {(() => {
        switch (ipState.currentStep) {
          case 1:
            return <PrimaryContact />;
          case 2:
            return <BillingDetails />;
          case 3:
            return <RegionGroup />;
          case 4:
            return <GroupStepsContainer />;
          case 5:
            return <InstrumentalEntrySummary />;
          default:
            return <PrimaryContact />;
        }
      })()}
    </div>
  );
};

export default InstrumentalStepsContainer;
