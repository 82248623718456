import React from 'react';
import { useIpContext } from '@lib/ipContext';
import cx from 'classnames';
import { Link } from 'gatsby';

const InstrumentalHeader = () => {
  const { ipState } = useIpContext();

  return (
    <div className=' pt-[46px] sm:pt-[36px] xl:pt-[77px] pb-[70px] sm:pb-[90px] xl:pb-[146px] relative'>
      <Link
        to='/'
        className='btn-entry absolute top-11 sm:top-9  xl:top-20 right-0 z-20  '>
        Go Home
      </Link>
      <h3 className='blockH6 mb-3 xl:mb-[30px]'>NZCT Chamber Music Contest</h3>
      <h2 className='blockH1'>2024 Entry Form</h2>

      <div className='absolute bottom-2 sm:bottom-4 left-0'>
        {ipState.currentStep !== 5 ? (
          <p className='blockH7'>
            <span>{ipState.currentStep}</span> of 4
          </p>
        ) : (
          <p className='blockH7'>Entry Summary</p>
        )}
      </div>
    </div>
  );
};

export default InstrumentalHeader;
